<template>
  <div>
    <button
      v-if="!loggedInAsPatient"
      class="btn btn-outline-blue mb-3"
      @click="addNewConsent"
    >
      <icon type="plus" /> Add Consent
    </button>

    <alert v-if="consentsLoading || isLoading" />
    <alert v-else-if="consents.length == 0" :hideLoader="true" class="my-4">
      No Consents Attached
    </alert>
    <div v-else class="card table-container">
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Attachments</th>
            <th>Status</th>
            <th>Status Info</th>
            <th v-if="!loggedInAsPatient"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="consent in consents" :key="consent.id">
            <td>
              <b class="mobile-tablet-only mr-2">Title:</b>
              {{ consent.consent.title }}
              <span class="cursor-pointer" @click="showDescription(consent)">
                <icon type="info-circle fa-lg" />
              </span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Attachments:</b>
              <button class="btn btn-theme" @click="viewAttachments(consent)">
                <icon type="eye fa-lg" />
              </button>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status:</b>
              <span
                class="font-weight-bold p-2"
                :class="
                  {
                    active: 'badge badge-pill badge-success',
                    withdrawn: 'badge badge-pill badge-danger',
                    null: 'badge badge-pill badge-secondary',
                  }[consent.status]
                "
              >
                {{ textToUpperCase(consent.status) }}
              </span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status Info:</b>
              <div class="d-flex flex-column">
                <small
                  v-if="consent.activated_at && consent.status == 'active'"
                >
                  <b>Activated At:</b>
                  {{ prettyDate(consent.activated_at) }}
                </small>
                <small
                  v-if="consent.activated_by && consent.status == 'active'"
                >
                  <b>Activated By:</b>
                  {{ consent.activated_by.name }}
                </small>
                <small
                  v-if="consent.withdrawn_at && consent.status == 'withdrawn'"
                >
                  <b>Withdrawn At:</b>
                  {{ prettyDate(consent.withdrawn_at) }}
                </small>
                <small
                  v-if="consent.withdrawn_by && consent.status == 'withdrawn'"
                >
                  <b>Withdrawn By:</b>
                  {{ consent.withdrawn_by.name }}
                </small>
              </div>
            </td>
            <td v-if="!loggedInAsPatient" class="td-actions">
              <div class="d-flex justify-content-lg-end justify-content-center">
                <button
                  v-if="consent.status == 'withdrawn'"
                  class="btn btn-success btn-sm ml-3"
                  @click="activateConsentHandler(consent)"
                >
                  <i
                    class="fas fa-spin fa-circle-notch"
                    v-if="activateUserConsentLoading == consent.id"
                  ></i>
                  <span v-else>Activate</span>
                </button>
                <button
                  v-if="consent.status == 'active'"
                  class="btn btn-warning btn-sm ml-3"
                  @click="withdrawConsentHandler(consent)"
                >
                  <i
                    class="fas fa-spin fa-circle-notch"
                    v-if="withdrawUserConsentLoading == consent.id"
                  ></i>
                  <span v-else>Withdraw</span>
                </button>
                <button
                  class="btn btn-danger btn-sm ml-3"
                  @click="deleteConsentHandler(consent)"
                >
                  <i
                    class="fas fa-spin fa-circle-notch"
                    v-if="detachUserConsentLoading == consent.id"
                  ></i>
                  <icon v-else type="trash-alt" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConsentAttachmentsModal
      v-if="selectedConsent"
      :consent="selectedConsent"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import helpers from "@/utils/helpers";
import ConsentAttachmentsModal from "@/components/consent/ConsentAttachmentsModal.vue";

export default {
  name: "PatientConsents",
  components: {
    ConsentAttachmentsModal,
  },
  data() {
    return {
      isLoading: true,
      selectedConsent: null,
    };
  },
  async mounted() {
    if (this.patientId) {
      await this.getConsents({ userId: this.patientId });
      this.isLoading = false;
    }
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
      consents: (state) => state.consents.userConsents.data,
      consentsLoading: (state) => state.consents.userConsents.loading,
      detachUserConsentLoading: (state) =>
        state.consents.detachUserConsentLoading,
      activateUserConsentLoading: (state) =>
        state.consents.activateUserConsentLoading,
      withdrawUserConsentLoading: (state) =>
        state.consents.withdrawUserConsentLoading,
    }),
    patientId() {
      if (this.patient.user_id) {
        return this.patient.user_id;
      }
      return null;
    },
    loggedInAsPatient() {
      return this.loggedInUser?.isPatient;
    },
  },
  methods: {
    ...mapActions({
      getConsents: "consents/getUserConsents",
      deleteConsent: "consents/detachConsentFromUser",
      activateConsent: "consents/activateConsentForUser",
      withdrawConsent: "consents/withdrawConsentForUser",
    }),
    addNewConsent: function () {
      this.$router.push({
        name: "patients.consents.create",
        params: { id: this.patientId },
        query: { src: "consents" },
      });
    },
    deleteConsentHandler: function (consent) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const result = await vm.deleteConsent({
              userId: vm.patientId,
              userConsentId: consent.id,
            });
            if (result) {
              vm.getConsents({ userId: vm.patientId });
            }
          }
        });
    },
    activateConsentHandler: async function (consent) {
      const vm = this;
      const success = await vm.activateConsent({
        userId: vm.patientId,
        userConsentId: consent.id,
      });
      if (success) {
        vm.getConsents({ userId: vm.patientId });
      }
    },
    withdrawConsentHandler: function (consent) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, withdraw it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const success = await vm.withdrawConsent({
              userId: vm.patientId,
              userConsentId: consent.id,
            });
            if (success) {
              vm.getConsents({ userId: vm.patientId });
            }
          }
        });
    },
    viewAttachments: function (consent) {
      this.selectedConsent = consent;
      setTimeout(() => {
        this.$modal.show("consentAttachmentsModal");
      });
    },
    showDescription: function (consent) {
      const description =
        consent?.consent?.description || "No description available";
      Swal.fire({
        html: `<div class="consents-description-container">
          <h3>Consent Description</h3>
          <div class="description-container text-left border rounded p-3 mb-3">
          ${description}
          </div>
          <hr>
          </div>`,
        showCancelButton: true,
        cancelButtonText: "Close",
        cancelButtonColor: "#6e84a3",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "px-0 booking-note",
        },
      });
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
    textToUpperCase: function (status) {
      return helpers.textToUpperCase(status);
    },
  },
};
</script>
<style lang="scss">
.consents-description-container {
  .description-container {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>
